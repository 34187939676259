.Wrapper {
  height: 46.1rem;
  width: 88rem;
  border-radius: 12px;
  padding: 2.4rem 4rem 7.4rem 4rem;

  background-color: var(--pure-white);
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.Heading {
  font-size: var(--font-24);
  font-weight: 700;
}
.SubHeading {
  font-size: var(--font-16);
  font-weight: 400;
}
.UsageTable td {
  font-size: var(--font-16);
  font-weight: 500;
  padding: 1rem;
}
.UsageTable {
  border-collapse: collapse;
}
.UsageTable tr {
  border-bottom: 1px solid var(--light-grey);
}
.TextAlignLeft {
  text-align: left;
}
.TextAlignRight {
  text-align: right;
}
.CreditsInfoWrapper {
  display: flex;

  gap: 2rem;
}
.InfoText {
  font-size: var(--font-14);
  font-weight: 600;
}
.TableData {
  color: var(--sec-blue);
}

@media only screen and (max-width: 350px) {
  .Wrapper {
    background-color: transparent;
  }
}
