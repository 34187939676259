.Wrapper {
  background-color: var(--pure-white);
  height: 55.2rem;
  width: 69.6rem;
  border-radius: 12px;
  padding: 3.2rem 8rem;

  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
}
.PrimaryText {
  font-size: var(--font-16);
  font-weight: 500;
}
.SecondaryText {
  font-size: var(--font-16);
  font-weight: 400;
}
.KeyWrapper {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  width: 50rem;
  word-break: break-all;
}
.CopyBtn {
  border: 1px solid var(--font-grey);
}
.NoteWrapper {
  width: 50rem;
  color: var(--primary-orange);
}
.ButtonsWrapper {
  display: flex;
  gap: 3rem;
}
.FinishBtn {
  border: 1px solid var(--font-grey);
}

@media only screen and (max-width: 720px) {
  .Wrapper {
    padding: 3rem;
    width: 60rem;
  }
}
@media only screen and (max-width: 500px) {
  .Wrapper {
    width: 50rem;
  }
  .NoteWrapper {
    width: 40rem;
  }
  .KeyWrapper {
    width: 40rem;
  }
}
