.Wrapper {
  display: flex;
  gap: 3rem;

  padding: 3rem;
}
.AccPendingWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
