.Wrapper {
  height: 46.1rem;
  width: 88rem;
  border-radius: 12px;
  padding: 2.4rem 4rem 7.4rem 4rem;

  background-color: var(--pure-white);

  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.Heading {
  font-size: var(--font-24);
  font-weight: 700;
}
.SubHeading {
  font-size: var(--font-16);
  font-weight: 400;
}
.InfoText {
  font-size: var(--font-18);
  font-weight: 400;
}
.InfoWrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
}

@media only screen and (max-width: 350px) {
  .Wrapper {
    background-color: transparent;
  }
}
