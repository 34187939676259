@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-family: "Inter", sans-serif;
  font-size: 62.5%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  /* Font Sizes */
  --font-11: 1.1rem;
  --font-12: 1.2rem;
  --font-13: 1.3rem;
  --font-135: 1.35rem;
  --font-14: 1.4rem;
  --font-15: 1.5rem;
  --font-16: 1.6rem;
  --font-17: 1.7rem;
  --font-18: 1.8rem;
  --font-20: 2rem;
  --font-22: 2.2rem;
  --font-24: 2.4rem;
  --font-26: 2.6rem;
  --font-28: 2.8rem;
  --font-30: 3rem;
  --font-32: 3.2rem;
  --font-34: 3.4rem;
  --font-36: 3.6rem;
  --font-38: 3.8rem;
  --font-40: 4rem;
  --font-42: 4.2rem;
  --font-45: 4.5rem;
  --font-48: 4.8rem;
  --font-52: 5.2rem;
  --font-70: 7rem;
  --fontv-4: 4vw;
  --fontv-3: 3vw;
  --fontv-35: 3.5vw;
  /* Colors */
  --pure-white: #ffffff;

  --pure-black: #000000;
  --primary-black: #222222;
  --dark-black: #200e32;
  --sec-black: #444444;
  --ter-black: #888888;
  --font-black: #0f1629;

  --font-grey: #3e424a;
  --primary-grey: #767676;
  --sec-grey: #aaaaaa;
  --ter-grey: #bbbbbb;
  --line-grey: #cccccc;
  --lig-grey: #dddddd;
  --quad-grey: #e0e0e0;
  --pent-grey: #f8f8f8;
  --hex-grey: #fafafa;
  --sept-grey: #dbdcdf;
  --deca-grey: #f9fcff;
  --holdingsTable-grey: #768396;
  --oct-grey: #dee1e6;
  --light-grey: #eeeeee;
  --peg-grey: #f3f3f4;
  --light-bg-grey: #fdfdfd;
  --main-grey: #eff2f5;
  --comp-grey: #b4becc;
  --background-grey: #eff2f5;

  --primary-orange: #f36a01;
  --orange: #ee734f;
  --orange-light: #fdeeea;

  --warning-yellow: #ffc107;

  --primary-blue: #222c65;
  --sec-mark-blue: #2e7bff;
  --ter-blue: #0057ff;
  --main-blue: #0141cf;
  --sec-blue: #0052fe;
  --light-blue: #f6f9ff;
  --light-quad-blue: #0052fe2e;
  --peg-blue: #e9f0ff;
  --light-sec-blue: #e7efff;
  --light-ter-blue: #1874d2;
  --link-blue: #2997f9;

  --primary-red: #d70808;
  --loss-red: #e75757;
  --light-red: #d65745;
  --peg-red: #ffeded;
  --peg-red-font: #be3338;
  --sec-red: #ef4136;
  --ter-red: #f7324c;

  --success-green: #79ea86;
  --primary-green: #009229;
  --profit-green: #00a92f;
  --sec-green: #25e006;
  --border-grey: #dddddd;
  --divider-grey: #bdbec1;
  --peg-green: #d7fdef;
  --peg-green-font: #068e5d;
  --sea-green: #309799;
  --sea-green-light: #e9f4f5;

  --blue-bg-light: #edf2ff;
  --blue-bg-dark: #200e32;
  --bitbns: #9b2ad6;

  --lavender: #7557d4;
  --lavender-light: #eeebfa;
  --orange: #ee734f;
  --orange-light: #fdeeea;
  --sea-green: #309799;
  --sea-green-light: #e9f4f5;
  /* tds colors */
  --tds-light-blue: #69c0ff;
  --tds-blue: #2f82ff;
  --tds-orange: #ff7a45;
  --tds-green: #73d13d;
  --tds-red: #f5222d;
  --tds-purple: #b37feb;
  --tds-dark-purple: #9b51e0;
  /* Coinswitch colors */
  --coinswitch-primary-purple: #514ed8;
  --twitter-blue: #4a99e9;
  /* Gradiants */
  --blue-btn-bg: linear-gradient(90.13deg, #005dfc -7.06%, #0041b1 104.45%);
  --prefill-blue-gradient: linear-gradient(
    180deg,
    #1800e2 0%,
    rgba(91, 9, 141, 0.98) 100%
  );
  --prefill-orange-gradient: linear-gradient(180deg, #f33a00 0%, #fa9a29 100%);
  --waiting-list-confirmation-text-gradient: linear-gradient(
    90.13deg,
    #ff0051 -7.06%,
    #bd00ff 104.45%
  );

  --lavender-gradient: linear-gradient(
    to right,
    #7557d4,
    #947ce0,
    #b1a0ea,
    #cfc5f3,
    #eeebfa
  );
  --orange-gradient: linear-gradient(
    to right,
    #ee734f,
    #f79275,
    #fdb19b,
    #ffcfc2,
    #fdeeea
  );
  --sea-green-gradient: linear-gradient(
    to right,
    #309799,
    #67aeb0,
    #94c5c8,
    #bfdcde,
    #e9f4f5
  );
}

html::-webkit-scrollbar {
  width: 1rem;
}

html::-webkit-scrollbar-track {
  background-color: rgba(230, 230, 230, 0.2);
}

html::-webkit-scrollbar-thumb {
  background-color: rgba(120, 120, 120, 0.3);
  outline: 1px solid slategrey;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

input,
Button,
select,
textarea {
  font-family: "Inter", sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

.DisabledBtn {
  opacity: 0.5;
}

body {
  background-color: #eff2f5;
  background-color: var(--background-grey);
}

.TextAlignLeft {
  text-align: left;
}

.TextAlignRight {
  text-align: right;
}
@media only screen and (max-width: 600px) {
  :root {
    font-size: 50%;
  }
}
@media only screen and (max-width: 400px) {
  :root {
    font-size: 40%;
  }
}

.Navbar_Navbar__2hRdE {
  position: relative;
  margin-bottom: 2rem;
}
.Navbar_Wrapper__3xNLx {
  height: 7.2rem;
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2.5rem;
  position: relative;

  background-color: var(--pure-white);
}
.Navbar_LogoCategoriesWrapper__2QMst {
  display: flex;
  align-items: center;
  height: 100%;
}
.Navbar_Logo__3OGmb {
  width: 9rem;
  cursor: pointer;
  object-fit: contain;
}
.Navbar_CategoriesWrapper__3kbKW {
  display: flex;
  align-items: center;
  grid-gap: 3.2rem;
  gap: 3.2rem;
  margin: 0 4rem;
  height: 100%;
}
.Navbar_CategoryWrapper__oHM1b {
  height: 100%;
  display: flex;
  align-items: center;
}
.Navbar_CategoryWrapperActive__jWTrO {
  border-bottom: 4px solid var(--main-blue);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.Navbar_ActiveCategory__2rw4x {
  color: var(--main-blue) !important;
}
.Navbar_DisabledCategory__3fLch {
  color: var(--sec-grey) !important;
  cursor: not-allowed !important;
}
.Navbar_Category__3g5sK {
  color: var(--primary-blue);
  font-size: var(--font-16);
  text-transform: capitalize;
  transition: color 0.15s ease-in-out;
  cursor: pointer;
}
.Navbar_Profile__1ilgk {
  cursor: pointer;
}
.Navbar_ArrowDown__TrX83 {
  margin-top: 1.8rem;
  cursor: pointer;
}
.Navbar_ProfileWrapper__14Ork {
  display: flex;
  cursor: pointer;
}
.Navbar_ProfileContainer__95EeX {
  display: flex;
}
.Navbar_ProfileName__1wAFs {
  margin: 1rem;
  font-size: var(--font-16);
}
.Navbar_ProfileErrorIcon__HTik7 {
  margin-left: 1rem;
}
.Navbar_Hamburger__3XoY8 {
  display: none;
  cursor: pointer;
}
.Navbar_BorderBottom__2tW6S {
  width: 100%;
  height: 1px;
  background-color: #dedfe2;
  border: none;
  outline: none;
}
.Navbar_HamburgerCategoriesWrapper__2RbsN {
  display: none;
}
.Navbar_Dropdown__1YpsA {
  position: absolute;
  width: 21.4rem;
  background: var(--pure-white);
  box-shadow: 0px 0px 4px rgba(9, 17, 33, 0.18);
  border-radius: 0.8rem;
  display: grid;
  padding: 1rem;
  z-index: 2;
  top: 6.5rem;
  right: 0;
}
.Navbar_DropdownItems__3LJ0Q {
  font-weight: 500;
  font-size: 16px;
  padding: 1rem;
  cursor: pointer;
}
.Navbar_Line__2SZj9 {
  width: 17.4rem;
  margin: 1rem;
  background-color: var(--ter-grey);
  height: 1px;
  border: none;
  outline: none;
}
.Navbar_Navbar__2hRdE .Navbar_MenuBtn__2I8hO {
  border: 0px;

  font-size: var(--font-20);
  font-weight: 400;
}

.Navbar_ActiveMenu__1QDjF {
  color: var(--sec-blue);
  border: 0px;
  font-size: var(--font-20);
  font-weight: 400;
}

@media only screen and (max-width: 950px) {
  .Navbar_Profile__1ilgk,
  .Navbar_ProfileName__1wAFs,
  .Navbar_ArrowDown__TrX83,
  .Navbar_BorderBottom__2tW6S,
  .Navbar_CategoriesWrapper__3kbKW,
  .Navbar_ProfileErrorIcon__HTik7,
  .Navbar_Dropdown__1YpsA {
    display: none;
  }
  .Navbar_Hamburger__3XoY8 {
    display: flex;
  }
  .Navbar_HamburgerCategoriesWrapper__2RbsN {
    position: absolute;
    z-index: 10;
    background-color: var(--pure-white);
    width: 100%;
    display: flex;
    grid-gap: 3.2rem;
    gap: 3.2rem;
    flex-direction: column;
    padding: 1rem 2rem;
    transition: visibility 0.3s, height 0.15s, opacity 0.15s ease-in-out;
  }
  .Navbar_CategoryWrapper__oHM1b {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  .Navbar_Category__3g5sK {
    font-size: var(--font-18);
    font-weight: 500;
  }
  .Navbar_Logo__3OGmb {
    width: 8rem;
  }
  .Navbar_CategoryWrapperActive__jWTrO {
    border: none;
  }
  .Navbar_LowerNavWrapper__1BREa {
    display: flex;
    flex-direction: column;
    grid-gap: 3.2rem;
    gap: 3.2rem;
    padding-top: 3.2rem;
    border-top: 1px solid var(--sec-grey);
  }
}


.Loader_LoaderMain__2kZa2 {
  border: 10px solid var(--main-grey);
  border-radius: 50%;
  border-top: 1rem solid var(--light-ter-blue);
  width: 10rem;
  height: 10rem;
  animation: Loader_spin__2OIhV 2s linear infinite;
  margin: auto;
}

.Loader_LoaderButton__3RZEI {
  border: 5px solid var(--main-grey);
  border-radius: 50%;
  border-top: 5px solid var(--light-ter-blue);
  width: 3rem;
  height: 3rem;
  animation: Loader_spin__2OIhV 2s linear infinite;
}

.Loader_LoaderRedButton__1__7Y {
  border-top: 5px solid var(--light-red);
}

@keyframes Loader_spin__2OIhV {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.Button_Button__HOrbw {
  border: 1px solid var(--light-grey);
  border-radius: 0.5rem;
  padding: 1rem 2rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: var(--font-16);
  justify-content: center;
  color: var(--primary-black);
  /* text-transform: capitalize; */
  grid-gap: 1rem;
  gap: 1rem;
  background-color: transparent;
}
.Button_ButtonIcon__3ytTe {
  height: 2rem;
}

.Button_PrimaryBtn__Fnrni {
  background-color: var(--sec-blue);
  color: var(--pure-white);
}

.LayoutComponent_Wrapper__1ZcC2 {
  width: 100%;
  display: flex;
}
.LayoutComponent_LeftWrapper__2Ob4f {
  width: 50%;
  min-height: 100vh;
  background-color: var(--light-sec-blue);
  padding: 4.2rem 6.5rem;
}
.LayoutComponent_UpperLogo__2eq4M,
.LayoutComponent_Logo__5c1m2 {
  height: 2.5rem;
  object-fit: contain;
}
.LayoutComponent_UpperLogo__2eq4M {
  display: none;
}
.LayoutComponent_Title__2KCAa {
  font-weight: 700;
  font-size: var(--font-32);
  line-height: var(--font-48);
  letter-spacing: 0.2px;
  color: var(--font-black);
  margin: 12rem auto 0;
}
.LayoutComponent_LayoutAsset__3wSVL {
  display: block;
  width: 47.2rem;
  object-fit: contain;
  margin: 5rem auto 0;
}
.LayoutComponent_RightWrapper__19wV4 {
  width: 50%;
  /* height: 100%; */
  min-height: 100vh;
  display: grid;
  grid-template-rows: -webkit-max-content 1fr;
  grid-template-rows: max-content 1fr;
  padding: 3.3rem 7.3rem;
  background-color: var(--pure-white);
}
.LayoutComponent_RightWrapperHeader__15uOg {
  font-weight: 400;
  font-size: var(--font-15);
  line-height: var(--font-20);
  text-align: right;
  letter-spacing: 0.2px;
  color: var(--font-black);
}
.LayoutComponent_RightWrapperHeaderLink__1qBTY {
  font-weight: 600;
  color: var(--main-blue);
  text-decoration: underline;
}
.LayoutComponent_RightWrapperHeaderLink__1qBTY:hover {
  color: var(--main-blue);
  text-decoration: underline;
}
.LayoutComponent_RenderComponentWrapper__1i5vt {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
@media only screen and (max-width: 1250px) {
  .LayoutComponent_LeftWrapper__2Ob4f {
    padding: 4.2rem 2.5rem;
  }
  .LayoutComponent_Title__2KCAa {
    font-size: var(--font-30);
  }
  .LayoutComponent_LayoutAsset__3wSVL {
    width: 35.6rem;
  }
  .LayoutComponent_RightWrapper__19wV4 {
    padding: 3.3rem 2.5rem;
  }
}
@media only screen and (max-width: 1050px) {
  .LayoutComponent_Wrapper__1ZcC2 {
    flex-direction: column;
    height: auto;
    min-height: auto;
  }
  .LayoutComponent_LeftWrapper__2Ob4f,
  .LayoutComponent_RightWrapper__19wV4 {
    width: 100%;
    min-height: auto;
  }
  .LayoutComponent_Title__2KCAa {
    text-align: center;
    margin-top: 10rem;
  }
}
@media only screen and (max-width: 600px) {
  .LayoutComponent_Wrapper__1ZcC2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-top: 3rem;
  }
  .LayoutComponent_UpperLogo__2eq4M {
    display: block;
    height: 2.5rem;
    margin-bottom: 3rem;
  }
  .LayoutComponent_LeftWrapper__2Ob4f {
    display: none;
  }
  .LayoutComponent_RightWrapper__19wV4 {
    display: flex;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    flex-direction: column-reverse;
    align-items: center;
    grid-gap: 3.6rem;
    gap: 3.6rem;
  }
}

.PhoneInputComponent_Input__1TA8G {
  display: grid;
  padding: 1rem;
}
.PhoneInputComponent_InputSignInPage__3j-mK {
  display: grid;
}
.PhoneInputComponent_FormHeading__1G_df {
  font-size: var(--font-14);
  font-weight: 600;
  margin-bottom: 0.5rem;
}
.PhoneInputComponent_PhoneInput__2KG5K {
  width: 100% !important;
  font-size: var(--font-16) !important;
  background: var(--pent-grey) !important;
  border: 2px solid var(--pent-grey) !important;
  font-weight: 500;
  border-radius: 0.6rem !important;
  padding: 2rem;
}
.PhoneInputComponent_PhoneInputButton__3zlCG {
  border: none !important;
  margin: 0.2rem !important;
  background-color: var(--white) !important;
  border-radius: 0.6rem !important;
}
.PhoneInputComponent_PhoneInputDropdown__E5Hkm {
  font-family: "Inter", sans-serif !important;
}
.PhoneInputComponent_PhoneInputDropdown__E5Hkm::-webkit-scrollbar {
  width: 1rem;
}
.PhoneInputComponent_PhoneInputDropdown__E5Hkm::-webkit-scrollbar-track {
  background-color: rgba(230, 230, 230, 0.2);
}
.PhoneInputComponent_PhoneInputDropdown__E5Hkm::-webkit-scrollbar-thumb {
  background-color: rgba(120, 120, 120, 0.3);
  outline: 1px solid slategrey;
}
.PhoneInputComponent_PhoneInputDropdown__E5Hkm {
  font-family: "Poppins", sans-serif !important;
}
.PhoneInputComponent_PhoneInputDropdown__E5Hkm::-webkit-scrollbar {
  width: 1.5rem;
}
.PhoneInputComponent_PhoneInputFocus__32PDP {
  width: 100% !important;
  border: 2px solid var(--light-ter-blue) !important;
  font-size: var(--font-16) !important;
  font-weight: 500;
  border-radius: 0.6rem !important;
  padding: 2rem;
}
.PhoneInputComponent_PhoneInputButtonFocus__3_5Fp {
  border: none !important;
  margin: 0.2rem !important;
  background-color: var(--white) !important;
  border-radius: 0.6rem !important;
}
.PhoneInputComponent_PhoneInputDropdownFocus__1lymz {
  font-family: "Inter", sans-serif !important;
}
.PhoneInputComponent_PhoneInputDropdownFocus__1lymz::-webkit-scrollbar {
  width: 1rem;
}
.PhoneInputComponent_PhoneInputDropdownFocus__1lymz::-webkit-scrollbar-track {
  background-color: rgba(230, 230, 230, 0.2);
}
.PhoneInputComponent_PhoneInputDropdownFocus__1lymz::-webkit-scrollbar-thumb {
  background-color: rgba(120, 120, 120, 0.3);
  outline: 1px solid slategrey;
}
.PhoneInputComponent_Mandatory__3WrBy::after {
  content: " *";
  color: var(--sec-red);
}
.SignUp_Wrapper__3v8WY {
  /* padding: 5rem; */
}
.SignUp_FormHeader__2aDRb {
  font-weight: 700;
  font-size: var(--font-32);
  color: var(--font-black);
  margin: 1rem 1rem 2.4rem 1rem;
}
.SignUp_FormName__3pxrs {
  display: flex;
  justify-content: space-between;
}
.SignUp_Input__3W6Ra {
  display: grid;
  padding: 1rem;
}
.SignUp_FormHeading__3nfVZ {
  font-size: var(--font-14);
  font-weight: 600;
  margin-bottom: 0.5rem;
}
.SignUp_Mandatory__ZJzxf::after {
  content: " *";
  color: var(--sec-red);
}
.SignUp_FormInputName__1THEd {
  width: 22rem;
  height: 4.8rem;
  background: var(--pent-grey);
  border-radius: 0.6rem;
  border: none;
  padding-left: 1rem;
  font-size: var(--font-16);
}
.SignUp_TermsLink__2w7e1,
.SignUp_TermsLink__2w7e1:hover {
  color: var(--sec-blue);
  text-decoration: underline;
}

.SignUp_FormInputName__1THEd:focus {
  outline: none;
  border: 0.2rem solid var(--sec-blue);
  background-color: var(--pure-white);
}

.SignUp_Input__3W6Ra p span:last-child {
  font-size: var(--font-12);
  font-weight: 500;
}
.SignUp_CodeInput__1vxCc {
  border-radius: 0.6rem !important;
  background: var(--pent-grey);
  border-radius: 0.6rem;
  border: none;
  font-size: var(--font-16);
  padding-left: 1rem;
  height: 4.8rem !important;
}
.SignUp_CodeInput__1vxCc:focus {
  outline: none;
  border: 0.2rem solid var(--sec-blue);
  background-color: var(--pure-white);
}
.SignUp_PolicyWrapper__3--t3 {
  display: flex;
  margin: 1.5rem 1.5rem 2rem 1.5rem;
  align-items: center;
}
.SignUp_PolicyCheckbox__167ht {
  cursor: pointer;
  width: 1.6rem;
  height: 1.6rem;
}
.SignUp_Policy__3eyBZ {
  padding: 0 4rem 0rem 1rem;
  font-size: var(--font-16);
}
.SignUp_SendBtn__14vRu {
  height: 4.8rem;
  justify-content: center;
  background: var(--sec-blue);
  border-radius: 0.6rem;
  color: var(--pure-white);
  margin: 0;
  width: 100%;
  border: none;
  font-size: 1.6rem;
  font-weight: 500;
}
.SignUp_RecaptchaWrapper__1oA1e {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 1rem 1rem 1rem;
}

.SignUp_ButtonWrapper__3n3Ql {
  width: 100%;
  padding: 0 1rem;
}
.SignUp_BuissnessWrapper__1rO8D {
  font-size: var(--font-16);
  text-align: center;
  margin-top: 3rem;
  font-weight: 500;
  cursor: pointer;
}
@media only screen and (max-width: 600px) {
  .SignUp_Wrapper__3v8WY {
    width: 100%;
  }
  .SignUp_FormHeader__2aDRb {
    font-size: var(--font-26);
  }
  .SignUp_FormInputName__1THEd {
    width: 100%;
  }
  .SignUp_Policy__3eyBZ {
    padding-right: 0;
  }
}
.SignUp_SendBtnDisabled__3nMzK {
  opacity: 0.5;
  cursor: not-allowed;
}

.SignUp_Wrapper__3WUnz {
  width: 100%;
  max-width: 153.6rem;
  height: 100%;
  margin: 0 auto;
}

.SignInComponent_Wrapper__3YEWD {
  width: 39rem;
}
.SignInComponent_Heading__1VjKV {
  font-weight: 700;
  font-size: var(--font-32);
  color: var(--font-black);
  margin-bottom: 2.4rem;
}
.SignInComponent_InputWrapper__gGAHo p {
  font-size: var(--font-14);
  font-weight: 600;
  color: var(--font-black);
  margin-bottom: 0.6rem;
}
.SignInComponent_Input__1acmX {
  display: grid;
  padding: 1rem;
}
.SignInComponent_FormHeading__e5tWo {
  font-size: var(--font-14);
  font-weight: 600;
  margin-bottom: 0.5rem;
}
.SignInComponent_CodeInput__2JlM1 {
  border-radius: 0.6rem !important;
  background: var(--pent-grey);
  border-radius: 0.6rem;
  border: none;
  font-size: var(--font-16);
  padding-left: 1rem;
  height: 4.8rem !important;
}
.SignInComponent_CodeInput__2JlM1:focus {
  outline: none;
  border: 0.2rem solid var(--sec-blue);
  background-color: var(--pure-white);
}

.SignInComponent_ButtonWrapper__bNgJq {
  width: 100%;
  padding: 0 1rem;
}
.SignInComponent_Button__17fxu {
  width: 100%;
  background-color: var(--sec-blue);
  justify-content: center;
  color: var(--pure-white);
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
  margin-top: 1.5rem;
  font-weight: 500;
}
.SignInComponent_RecaptchaWrapper__Nxod9 {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
}
.SignInComponent_SendBtnDisabled__3fGw4 {
  opacity: 0.5;
  cursor: not-allowed;
}
@media only screen and (max-width: 600px) {
  .SignInComponent_Heading__1VjKV {
    font-size: var(--font-24);
  }
}
@media only screen and (max-width: 430px) {
  .SignInComponent_Wrapper__3YEWD {
    width: 100%;
  }
}

.SignIn_Wrapper__2_DNF {
  width: 100%;
  max-width: 153.6rem;
  height: 100%;
  margin: 0 auto;
  min-height: 100vh;
}
@media only screen and (max-width: 1050px) {
  .SignIn_Wrapper__2_DNF {
    min-height: auto;
  }
}

.Otp_Wrapper__2zxzv {
  width: 42rem;
  text-align: center;
}
.Otp_Header__3seaB {
  font-weight: 700;
  font-size: var(--font-32);
  color: var(--font-black);
  margin-bottom: 1.4rem;
}
.Otp_SubText__KGqoL {
  font-size: var(--font-14);
  margin: 1.5rem;
}
.Otp_Number__3y5Zy {
  font-size: var(--font-15);
  font-weight: 500;
}
.Otp_NoContainer__2gOoF {
  border: 0.1rem solid var(--primary-grey);
  border-radius: 4.8rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0.5rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}
.Otp_FormWrapper__10Vbp {
  padding: 3rem 0;
}
.Otp_Input__-s4JJ {
  display: grid;
}
.Otp_FormTitle__1S6uH {
  font-size: var(--font-14);
  font-weight: 600;
  margin-bottom: 1rem;
  text-align: start;
}
.Otp_FormInputOtp__2BurN {
  height: 4.8rem;
  border-radius: 0.6rem;
  border: 0.2rem solid #1874d2;
  padding: 1.2rem;
  font-size: var(--font-16);
}

.Otp_Resend__3eHqq {
  text-align: start;
  font-size: var(--font-15);
  margin: 1rem 2rem 2rem 0rem;
}
.Otp_ResendLink__Vzz4y {
  color: var(--sec-blue);
  margin-left: 0.5rem;
  display: inline;
  cursor: pointer;
}
.Otp_ResendLink__Vzz4y:hover {
  text-decoration: underline;
}
.Otp_SendBtn__SxyEO {
  height: 4.8rem;
  justify-content: center;
  background: var(--ter-blue);
  border-radius: 0.6rem;
  color: white;
  border: none;
  width: 100%;
  font-size: 1.6rem;
  font-weight: 500;
}
.Otp_ArrowDown__2J4ld {
  margin-left: 1rem;
}
@media only screen and (max-width: 600px) {
  .Otp_Header__3seaB {
    font-size: var(--font-24);
  }
}

.OTPPage_Wrapper__25170 {
    width: 100%;
    max-width: 153.6rem;
    margin: 0 auto;
  }
  
.VerifyOTPPage_Wrapper__2uLU4 {
  width: 100%;
  max-width: 153.6rem;
  margin: 0 auto;
}

.PopUp_WrapperWrapper__9NP2V {
  position: fixed;
  height: 100%;
  width: 100%;
  right: 0;
  top: 0;
  z-index: 4;
  transition: background 0.3s ease-in-out;
}
.PopUp_Wrapper__1XvP4 {
  height: 100vh;
  min-height: 100%;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  position: fixed;
  right: 0px;
  background-color: white;
  z-index: 5;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  transition: transform 0.3s ease-out;
}
.PopUp_Wrapper__1XvP4::-webkit-scrollbar {
  width: 1rem;
}
@media only screen and (max-width: 560px) {
  .PopUp_Wrapper__1XvP4 {
    width: 100%;
  }
}

.PopUpCenter_Wrapper__31iUp {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.25s ease-out;
  background-color: rgba(1, 22, 45, 0.2);
  z-index: 20;
  opacity: 0;
}

.PopUpCenter_Container__3b9j1 {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  transition: opacity 0.15s ease-out, transform 0.15s ease-out;
  pointer-events: all;
  border: 1px solid var(--ter-black);
  box-shadow: 0px 4px 42px rgba(0, 0, 0, 0.15);
  margin: 1rem;
  border-radius: 0.8rem;
}

.ApiKeyPopup_Wrapper__1wWuy {
  background-color: var(--pure-white);
  height: 55.2rem;
  width: 69.6rem;
  border-radius: 12px;
  padding: 3.2rem 8rem;

  display: flex;
  align-items: center;
  flex-direction: column;
  grid-gap: 2rem;
  gap: 2rem;
}
.ApiKeyPopup_PrimaryText__2FM1_ {
  font-size: var(--font-16);
  font-weight: 500;
}
.ApiKeyPopup_SecondaryText__3pt0O {
  font-size: var(--font-16);
  font-weight: 400;
}
.ApiKeyPopup_KeyWrapper__deVYx {
  display: flex;
  flex-direction: column;
  grid-gap: 1.5rem;
  gap: 1.5rem;

  width: 50rem;
  word-break: break-all;
}
.ApiKeyPopup_CopyBtn__3odAn {
  border: 1px solid var(--font-grey);
}
.ApiKeyPopup_NoteWrapper__1dDxV {
  width: 50rem;
  color: var(--primary-orange);
}
.ApiKeyPopup_ButtonsWrapper__1rjns {
  display: flex;
  grid-gap: 3rem;
  gap: 3rem;
}
.ApiKeyPopup_FinishBtn__3wejY {
  border: 1px solid var(--font-grey);
}

@media only screen and (max-width: 720px) {
  .ApiKeyPopup_Wrapper__1wWuy {
    padding: 3rem;
    width: 60rem;
  }
}
@media only screen and (max-width: 500px) {
  .ApiKeyPopup_Wrapper__1wWuy {
    width: 50rem;
  }
  .ApiKeyPopup_NoteWrapper__1dDxV {
    width: 40rem;
  }
  .ApiKeyPopup_KeyWrapper__deVYx {
    width: 40rem;
  }
}

.WebhookStatusPopup_SuccessWrapper__-1Uwe,
.WebhookStatusPopup_FailWrapper__iUK8q {
  background-color: var(--pure-white);

  display: flex;
  flex-direction: column;
  grid-gap: 2rem;
  gap: 2rem;
  justify-content: center;
  align-items: center;

  height: 31.5rem;
  width: 58.3rem;
  border-radius: 16px;
  position: relative;
}
.WebhookStatusPopup_Heading__3waxA {
  font-size: var(--font-32);
  font-weight: 700;
  text-align: center;
}
.WebhookStatusPopup_SubHeading__1eEzv {
  font-size: var(--font-18);
  font-weight: 500;
  text-align: center;
}
.WebhookStatusPopup_CloseIcon__2ksXe {
  position: absolute;
  top: 2rem;
  right: 2rem;

  font-size: 2rem;
}
.WebhookStatusPopup_StatusIcon__29TLM {
  width: 5rem;
}
.WebhookStatusPopup_CloseBtn__335Wr {
  border: 1px solid var(--font-grey);
  width: 13rem;
}

@media only screen and (max-width: 500px) {
  .WebhookStatusPopup_SuccessWrapper__-1Uwe,
  .WebhookStatusPopup_Failwrapper__1TrLU {
    width: 45rem;
  }
}

.Input_DefaultClassName__i_x5W {
  border: 0px;
  border-radius: 0.5rem;
  padding: 1rem 1.4rem;
  outline: 0px;
  /* width: 25rem; */
  font-size: var(--font-16);
  background-color: var(--main-grey);
}

.AccountSettings_Wrapper__w9bEW {
  width: 88rem;
  border-radius: 12px;
  padding: 3.2rem 4rem;

  display: flex;
  flex-direction: column;
  grid-gap: 2rem;
  gap: 2rem;

  background-color: var(--pure-white);
}
.AccountSettings_InputWrapper__3HSTX {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 2rem;
  gap: 2rem;
}
.AccountSettings_InputWrapper__3HSTX input {
  background-color: var(--peg-grey);
}
.AccountSettings_LabelInputWrapper__WLteP {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
}
.AccountSettings_Btn__3w-vM {
  padding: 0rem 2rem;
  height: 5rem;

  align-self: flex-end;
}
.AccountSettings_Label__1GHbi {
  font-size: var(--font-14);
  font-weight: 600;
}
.AccountSettings_BtnWrapper__2Eu5n {
  display: flex;
  grid-gap: 2rem;
  gap: 2rem;
}
.AccountSettings_DisabledInput__2l3I0 {
  opacity: 0.5;
  cursor: not-allowed;
}
@media only screen and (max-width: 1220px) {
  .AccountSettings_Wrapper__w9bEW {
    width: 65rem;
  }
}
@media only screen and (max-width: 700px) {
  .AccountSettings_Wrapper__w9bEW {
    width: auto;
  }
}

.ListenEvents_Wrapper__UIhcf {
  height: 24.5rem;
  width: 88rem;
  border-radius: 12px;
  padding: 3.2rem 4rem;

  background-color: var(--pure-white);
  display: flex;
  flex-direction: column;
  grid-gap: 2rem;
  gap: 2rem;
  justify-content: center;
}
.ListenEvents_InputWrapper__2Z73Z {
  display: flex;
  grid-gap: 2rem;
  gap: 2rem;
}
.ListenEvents_LabelInputWrapper__23mNA {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
}
.ListenEvents_Btn__3BrfE {
  padding: 0rem 2rem;
  height: 5rem;

  align-self: flex-end;
}
.ListenEvents_Label__3VqoB {
  font-size: var(--font-14);
  font-weight: 600;
}
.ListenEvents_NoteWrapper__10I2s {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  align-items: center;
}
.ListenEvents_Note__1MC92 {
  color: var(--sec-blue);
  font-size: var(--font-14);
  font-weight: 500;
}
.ListenEvents_DocsIcon__Qic2f {
  width: 2rem;
}
@media only screen and (max-width: 1220px) {
  .ListenEvents_Wrapper__UIhcf {
    width: 65rem;
  }
}
@media only screen and (max-width: 700px) {
  .ListenEvents_Wrapper__UIhcf {
    width: auto;
  }
}

.SideMenu_Wrapper__3csqy {
  min-width: 30rem;
  height: 43rem;
  border-radius: 12px;
  padding: 2rem;

  background-color: var(--pure-white);
  display: flex;
  flex-direction: column;
  grid-gap: 1.5rem;
  gap: 1.5rem;
}
.SideMenu_MenuBtn__-gu7U {
  border: 0px;
  font-size: var(--font-20);
  font-weight: 400;
}
.SideMenu_ActiveMenu__37hz8 {
  color: var(--sec-blue);
  border: 0px;
  font-size: var(--font-20);
  font-weight: 400;
}

@media only screen and (max-width: 950px) {
  .SideMenu_Wrapper__3csqy {
    display: none;
  }
}

.AccountPage_Wrapper__2J8ub {
  display: flex;
  grid-gap: 3rem;
  gap: 3rem;

  padding: 3rem;
}
.AccountPage_InnerWrapper__J1gDf {
  display: flex;
  flex-direction: column;

  grid-gap: 3rem;

  gap: 3rem;
}

.ApiKey_Wrapper__33WDR {
  height: 31.3rem;
  width: 88rem;
  border-radius: 12px;
  padding: 3.2rem 4rem;

  background-color: var(--pure-white);
}
.ApiKey_ApiKeyTable__qwej2 {
  border-collapse: collapse;
  margin-top: 2rem;
}
.ApiKey_ApiKeyTable__qwej2 td {
  padding: 1rem;
}
.ApiKey_ApiKeyTable__qwej2 tr {
  border-top: 1px solid var(--light-grey);
}
.ApiKey_InfoText__1IPU- {
  font-size: var(--font-14);
  font-weight: 500;
}
.ApiKey_DeleteBtn__pJV7S {
  color: var(--ter-red);
  border: 1px solid var(--ter-red);
}
.ApiKey_DisableBtn__U6ox7 {
  text-decoration: underline;
  border: 0px;

  margin-top: 1rem;
}
.ApiKey_ApiKeyText__31pI_ {
  font-size: var(--font-12);
  font-weight: 400;

  text-align: left;
  word-break: break-all;
}

@media only screen and (max-width: 800px) {
  .ApiKey_Wrapper__33WDR {
    width: 70rem;
    padding: 2rem;
  }
}
@media only screen and (max-width: 400px) {
  .ApiKey_Wrapper__33WDR {
    background-color: transparent;
  }
}

.RefreshKeyWarningPopup_Wrapper__366We {
  height: 35.9rem;
  width: 58.3rem;
  border-radius: 16px;
  padding: 3rem;

  background-color: var(--pure-white);

  display: flex;
  flex-direction: column;
  grid-gap: 3rem;
  gap: 3rem;
  justify-content: center;
  align-items: center;
}
.RefreshKeyWarningPopup_Heading__3unjy {
  font-size: var(--font-24);
  width: 35rem;
  font-weight: 700;
  text-align: center;
}
.RefreshKeyWarningPopup_SubHeading__3z8Cc {
  font-size: var(--font-18);
  width: 35rem;

  font-weight: 500;
  text-align: center;
}
.RefreshKeyWarningPopup_CloseIcon__3qGgG {
  position: absolute;
  top: 2rem;
  right: 2rem;
  cursor: pointer;

  font-size: 2rem;
}
.RefreshKeyWarningPopup_BtnWrapper__2f1Hp {
  display: flex;
  grid-gap: 2rem;
  gap: 2rem;
}

.GenerateApiKey_Wrapper__2O8Fl {
  height: 25.8rem;
  width: 88rem;
  border-radius: 12px;
  padding: 3.2rem 4rem;
  background-color: var(--pure-white);

  display: flex;
  flex-direction: column;
  grid-gap: 2rem;
  gap: 2rem;
}
.GenerateApiKey_Divider__2giSQ {
  background-color: var(--light-grey);
}

.ApiKeyPage_Wrapper__3Czxq {
  display: flex;
  grid-gap: 3rem;
  gap: 3rem;

  padding: 3rem;
}

.ContactUs_Wrapper__1Qo5g {
  height: 46.1rem;
  width: 88rem;
  border-radius: 12px;
  padding: 2.4rem 4rem 7.4rem 4rem;

  background-color: var(--pure-white);

  display: flex;
  flex-direction: column;
  grid-gap: 2rem;
  gap: 2rem;
}
.ContactUs_Heading__j82Jk {
  font-size: var(--font-24);
  font-weight: 700;
}
.ContactUs_SubHeading__1-g1- {
  font-size: var(--font-16);
  font-weight: 400;
}
.ContactUs_InfoText__GArjJ {
  font-size: var(--font-18);
  font-weight: 400;
}
.ContactUs_InfoWrapper__24yVG {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
}

@media only screen and (max-width: 350px) {
  .ContactUs_Wrapper__1Qo5g {
    background-color: transparent;
  }
}

.ContactUsPage_Wrapper__2ZLoy {
  display: flex;
  grid-gap: 3rem;
  gap: 3rem;

  margin-top: 2rem;
  padding: 3rem;
}

.AccountPending_Wrapper__151z5 {
  display: flex;
  flex-direction: column;
  grid-gap: 2rem;
  gap: 2rem;

  justify-content: center;
  align-items: center;
  padding: 3rem;
}
.AccountPending_Heading__3o67y {
  font-size: var(--font-32);
  font-weight: 700;
  text-align: center;
}
.AccountPending_Description__17yim {
  font-size: var(--font-20);
  font-weight: 500;
  text-align: center;
}
.AccountPending_Highlight__ISZvS {
  color: var(--sec-blue);
  cursor: pointer;
}
.AccountPending_HeroImg__IJmgY {
  height: 28.76rem;
  width: 35.2rem;
  border-radius: 0px;
}
@media only screen and (max-width: 600px) {
  .AccountPending_Wrapper__151z5 {
    margin-top: 5rem;
  }
}

.UsageOverviewComponent_Wrapper__2Y5Dv {
  height: 46.1rem;
  width: 88rem;
  border-radius: 12px;
  padding: 2.4rem 4rem 7.4rem 4rem;

  background-color: var(--pure-white);
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
}
.UsageOverviewComponent_Heading__1RIOE {
  font-size: var(--font-24);
  font-weight: 700;
}
.UsageOverviewComponent_SubHeading__1NAoK {
  font-size: var(--font-16);
  font-weight: 400;
}
.UsageOverviewComponent_UsageTable__1LSad td {
  font-size: var(--font-16);
  font-weight: 500;
  padding: 1rem;
}
.UsageOverviewComponent_UsageTable__1LSad {
  border-collapse: collapse;
}
.UsageOverviewComponent_UsageTable__1LSad tr {
  border-bottom: 1px solid var(--light-grey);
}
.UsageOverviewComponent_TextAlignLeft__Y5Uad {
  text-align: left;
}
.UsageOverviewComponent_TextAlignRight__9S-BK {
  text-align: right;
}
.UsageOverviewComponent_CreditsInfoWrapper__3UOA2 {
  display: flex;

  grid-gap: 2rem;

  gap: 2rem;
}
.UsageOverviewComponent_InfoText__262s0 {
  font-size: var(--font-14);
  font-weight: 600;
}
.UsageOverviewComponent_TableData__3qH1v {
  color: var(--sec-blue);
}

@media only screen and (max-width: 350px) {
  .UsageOverviewComponent_Wrapper__2Y5Dv {
    background-color: transparent;
  }
}

.DashboardPage_Wrapper__1UBEq {
  display: flex;
  grid-gap: 3rem;
  gap: 3rem;

  padding: 3rem;
}
.DashboardPage_AccPendingWrapper__3dzgO {
  display: flex;
  justify-content: center;
  align-items: center;
}

