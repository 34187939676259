.Wrapper {
  width: 39rem;
}
.Heading {
  font-weight: 700;
  font-size: var(--font-32);
  color: var(--font-black);
  margin-bottom: 2.4rem;
}
.InputWrapper p {
  font-size: var(--font-14);
  font-weight: 600;
  color: var(--font-black);
  margin-bottom: 0.6rem;
}
.Input {
  display: grid;
  padding: 1rem;
}
.FormHeading {
  font-size: var(--font-14);
  font-weight: 600;
  margin-bottom: 0.5rem;
}
.CodeInput {
  border-radius: 0.6rem !important;
  background: var(--pent-grey);
  border-radius: 0.6rem;
  border: none;
  font-size: var(--font-16);
  padding-left: 1rem;
  height: 4.8rem !important;
}
.CodeInput:focus {
  outline: none;
  border: 0.2rem solid var(--sec-blue);
  background-color: var(--pure-white);
}

.ButtonWrapper {
  width: 100%;
  padding: 0 1rem;
}
.Button {
  width: 100%;
  background-color: var(--sec-blue);
  justify-content: center;
  color: var(--pure-white);
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
  margin-top: 1.5rem;
  font-weight: 500;
}
.RecaptchaWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
}
.SendBtnDisabled {
  opacity: 0.5;
  cursor: not-allowed;
}
@media only screen and (max-width: 600px) {
  .Heading {
    font-size: var(--font-24);
  }
}
@media only screen and (max-width: 430px) {
  .Wrapper {
    width: 100%;
  }
}
