.Input {
  display: grid;
  padding: 1rem;
}
.InputSignInPage {
  display: grid;
}
.FormHeading {
  font-size: var(--font-14);
  font-weight: 600;
  margin-bottom: 0.5rem;
}
.PhoneInput {
  width: 100% !important;
  font-size: var(--font-16) !important;
  background: var(--pent-grey) !important;
  border: 2px solid var(--pent-grey) !important;
  font-weight: 500;
  border-radius: 0.6rem !important;
  padding: 2rem;
}
.PhoneInputButton {
  border: none !important;
  margin: 0.2rem !important;
  background-color: var(--white) !important;
  border-radius: 0.6rem !important;
}
.PhoneInputDropdown {
  font-family: "Inter", sans-serif !important;
}
.PhoneInputDropdown::-webkit-scrollbar {
  width: 1rem;
}
.PhoneInputDropdown::-webkit-scrollbar-track {
  background-color: rgba(230, 230, 230, 0.2);
}
.PhoneInputDropdown::-webkit-scrollbar-thumb {
  background-color: rgba(120, 120, 120, 0.3);
  outline: 1px solid slategrey;
}
.PhoneInputDropdown {
  font-family: "Poppins", sans-serif !important;
}
.PhoneInputDropdown::-webkit-scrollbar {
  width: 1.5rem;
}
.PhoneInputFocus {
  width: 100% !important;
  border: 2px solid var(--light-ter-blue) !important;
  font-size: var(--font-16) !important;
  font-weight: 500;
  border-radius: 0.6rem !important;
  padding: 2rem;
}
.PhoneInputButtonFocus {
  border: none !important;
  margin: 0.2rem !important;
  background-color: var(--white) !important;
  border-radius: 0.6rem !important;
}
.PhoneInputDropdownFocus {
  font-family: "Inter", sans-serif !important;
}
.PhoneInputDropdownFocus::-webkit-scrollbar {
  width: 1rem;
}
.PhoneInputDropdownFocus::-webkit-scrollbar-track {
  background-color: rgba(230, 230, 230, 0.2);
}
.PhoneInputDropdownFocus::-webkit-scrollbar-thumb {
  background-color: rgba(120, 120, 120, 0.3);
  outline: 1px solid slategrey;
}
.Mandatory::after {
  content: " *";
  color: var(--sec-red);
}