.Wrapper {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  justify-content: center;
  align-items: center;
  padding: 3rem;
}
.Heading {
  font-size: var(--font-32);
  font-weight: 700;
  text-align: center;
}
.Description {
  font-size: var(--font-20);
  font-weight: 500;
  text-align: center;
}
.Highlight {
  color: var(--sec-blue);
  cursor: pointer;
}
.HeroImg {
  height: 28.76rem;
  width: 35.2rem;
  border-radius: 0px;
}
@media only screen and (max-width: 600px) {
  .Wrapper {
    margin-top: 5rem;
  }
}
