.Wrapper {
  height: 24.5rem;
  width: 88rem;
  border-radius: 12px;
  padding: 3.2rem 4rem;

  background-color: var(--pure-white);
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
}
.InputWrapper {
  display: flex;
  gap: 2rem;
}
.LabelInputWrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.Btn {
  padding: 0rem 2rem;
  height: 5rem;

  align-self: flex-end;
}
.Label {
  font-size: var(--font-14);
  font-weight: 600;
}
.NoteWrapper {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.Note {
  color: var(--sec-blue);
  font-size: var(--font-14);
  font-weight: 500;
}
.DocsIcon {
  width: 2rem;
}
@media only screen and (max-width: 1220px) {
  .Wrapper {
    width: 65rem;
  }
}
@media only screen and (max-width: 700px) {
  .Wrapper {
    width: auto;
  }
}
