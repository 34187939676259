.Wrapper {
  height: 35.9rem;
  width: 58.3rem;
  border-radius: 16px;
  padding: 3rem;

  background-color: var(--pure-white);

  display: flex;
  flex-direction: column;
  gap: 3rem;
  justify-content: center;
  align-items: center;
}
.Heading {
  font-size: var(--font-24);
  width: 35rem;
  font-weight: 700;
  text-align: center;
}
.SubHeading {
  font-size: var(--font-18);
  width: 35rem;

  font-weight: 500;
  text-align: center;
}
.CloseIcon {
  position: absolute;
  top: 2rem;
  right: 2rem;
  cursor: pointer;

  font-size: 2rem;
}
.BtnWrapper {
  display: flex;
  gap: 2rem;
}
