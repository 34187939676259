.Button {
  border: 1px solid var(--light-grey);
  border-radius: 0.5rem;
  padding: 1rem 2rem;
  width: fit-content;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: var(--font-16);
  justify-content: center;
  color: var(--primary-black);
  /* text-transform: capitalize; */
  gap: 1rem;
  background-color: transparent;
}
.ButtonIcon {
  height: 2rem;
}

.PrimaryBtn {
  background-color: var(--sec-blue);
  color: var(--pure-white);
}
