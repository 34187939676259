.Wrapper {
  height: 25.8rem;
  width: 88rem;
  border-radius: 12px;
  padding: 3.2rem 4rem;
  background-color: var(--pure-white);

  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.Divider {
  background-color: var(--light-grey);
}
