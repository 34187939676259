.Wrapper {
  min-width: 30rem;
  height: 43rem;
  border-radius: 12px;
  padding: 2rem;

  background-color: var(--pure-white);
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.MenuBtn {
  border: 0px;
  font-size: var(--font-20);
  font-weight: 400;
}
.ActiveMenu {
  color: var(--sec-blue);
  border: 0px;
  font-size: var(--font-20);
  font-weight: 400;
}

@media only screen and (max-width: 950px) {
  .Wrapper {
    display: none;
  }
}
