.SuccessWrapper,
.FailWrapper {
  background-color: var(--pure-white);

  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  align-items: center;

  height: 31.5rem;
  width: 58.3rem;
  border-radius: 16px;
  position: relative;
}
.Heading {
  font-size: var(--font-32);
  font-weight: 700;
  text-align: center;
}
.SubHeading {
  font-size: var(--font-18);
  font-weight: 500;
  text-align: center;
}
.CloseIcon {
  position: absolute;
  top: 2rem;
  right: 2rem;

  font-size: 2rem;
}
.StatusIcon {
  width: 5rem;
}
.CloseBtn {
  border: 1px solid var(--font-grey);
  width: 13rem;
}

@media only screen and (max-width: 500px) {
  .SuccessWrapper,
  .Failwrapper {
    width: 45rem;
  }
}
