.Wrapper {
  display: flex;
  gap: 3rem;

  padding: 3rem;
}
.InnerWrapper {
  display: flex;
  flex-direction: column;

  gap: 3rem;
}
