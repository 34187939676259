.Wrapper {
  width: 100%;
  display: flex;
}
.LeftWrapper {
  width: 50%;
  min-height: 100vh;
  background-color: var(--light-sec-blue);
  padding: 4.2rem 6.5rem;
}
.UpperLogo,
.Logo {
  height: 2.5rem;
  object-fit: contain;
}
.UpperLogo {
  display: none;
}
.Title {
  font-weight: 700;
  font-size: var(--font-32);
  line-height: var(--font-48);
  letter-spacing: 0.2px;
  color: var(--font-black);
  margin: 12rem auto 0;
}
.LayoutAsset {
  display: block;
  width: 47.2rem;
  object-fit: contain;
  margin: 5rem auto 0;
}
.RightWrapper {
  width: 50%;
  /* height: 100%; */
  min-height: 100vh;
  display: grid;
  grid-template-rows: max-content 1fr;
  padding: 3.3rem 7.3rem;
  background-color: var(--pure-white);
}
.RightWrapperHeader {
  font-weight: 400;
  font-size: var(--font-15);
  line-height: var(--font-20);
  text-align: right;
  letter-spacing: 0.2px;
  color: var(--font-black);
}
.RightWrapperHeaderLink {
  font-weight: 600;
  color: var(--main-blue);
  text-decoration: underline;
}
.RightWrapperHeaderLink:hover {
  color: var(--main-blue);
  text-decoration: underline;
}
.RenderComponentWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
@media only screen and (max-width: 1250px) {
  .LeftWrapper {
    padding: 4.2rem 2.5rem;
  }
  .Title {
    font-size: var(--font-30);
  }
  .LayoutAsset {
    width: 35.6rem;
  }
  .RightWrapper {
    padding: 3.3rem 2.5rem;
  }
}
@media only screen and (max-width: 1050px) {
  .Wrapper {
    flex-direction: column;
    height: auto;
    min-height: auto;
  }
  .LeftWrapper,
  .RightWrapper {
    width: 100%;
    min-height: auto;
  }
  .Title {
    text-align: center;
    margin-top: 10rem;
  }
}
@media only screen and (max-width: 600px) {
  .Wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-top: 3rem;
  }
  .UpperLogo {
    display: block;
    height: 2.5rem;
    margin-bottom: 3rem;
  }
  .LeftWrapper {
    display: none;
  }
  .RightWrapper {
    display: flex;
    height: fit-content;
    flex-direction: column-reverse;
    align-items: center;
    gap: 3.6rem;
  }
}
