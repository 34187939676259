.Wrapper {
  width: 100%;
  max-width: 153.6rem;
  height: 100%;
  margin: 0 auto;
  min-height: 100vh;
}
@media only screen and (max-width: 1050px) {
  .Wrapper {
    min-height: auto;
  }
}
