.Wrapper {
  width: 88rem;
  border-radius: 12px;
  padding: 3.2rem 4rem;

  display: flex;
  flex-direction: column;
  gap: 2rem;

  background-color: var(--pure-white);
}
.InputWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}
.InputWrapper input {
  background-color: var(--peg-grey);
}
.LabelInputWrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.Btn {
  padding: 0rem 2rem;
  height: 5rem;

  align-self: flex-end;
}
.Label {
  font-size: var(--font-14);
  font-weight: 600;
}
.BtnWrapper {
  display: flex;
  gap: 2rem;
}
.DisabledInput {
  opacity: 0.5;
  cursor: not-allowed;
}
@media only screen and (max-width: 1220px) {
  .Wrapper {
    width: 65rem;
  }
}
@media only screen and (max-width: 700px) {
  .Wrapper {
    width: auto;
  }
}
