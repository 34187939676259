.Wrapper {
  height: 31.3rem;
  width: 88rem;
  border-radius: 12px;
  padding: 3.2rem 4rem;

  background-color: var(--pure-white);
}
.ApiKeyTable {
  border-collapse: collapse;
  margin-top: 2rem;
}
.ApiKeyTable td {
  padding: 1rem;
}
.ApiKeyTable tr {
  border-top: 1px solid var(--light-grey);
}
.InfoText {
  font-size: var(--font-14);
  font-weight: 500;
}
.DeleteBtn {
  color: var(--ter-red);
  border: 1px solid var(--ter-red);
}
.DisableBtn {
  text-decoration: underline;
  border: 0px;

  margin-top: 1rem;
}
.ApiKeyText {
  font-size: var(--font-12);
  font-weight: 400;

  text-align: left;
  word-break: break-all;
}

@media only screen and (max-width: 800px) {
  .Wrapper {
    width: 70rem;
    padding: 2rem;
  }
}
@media only screen and (max-width: 400px) {
  .Wrapper {
    background-color: transparent;
  }
}
