.Navbar {
  position: relative;
  margin-bottom: 2rem;
}
.Wrapper {
  height: 7.2rem;
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2.5rem;
  position: relative;

  background-color: var(--pure-white);
}
.LogoCategoriesWrapper {
  display: flex;
  align-items: center;
  height: 100%;
}
.Logo {
  width: 9rem;
  cursor: pointer;
  object-fit: contain;
}
.CategoriesWrapper {
  display: flex;
  align-items: center;
  gap: 3.2rem;
  margin: 0 4rem;
  height: 100%;
}
.CategoryWrapper {
  height: 100%;
  display: flex;
  align-items: center;
}
.CategoryWrapperActive {
  border-bottom: 4px solid var(--main-blue);
  width: fit-content;
}
.ActiveCategory {
  color: var(--main-blue) !important;
}
.DisabledCategory {
  color: var(--sec-grey) !important;
  cursor: not-allowed !important;
}
.Category {
  color: var(--primary-blue);
  font-size: var(--font-16);
  text-transform: capitalize;
  transition: color 0.15s ease-in-out;
  cursor: pointer;
}
.Profile {
  cursor: pointer;
}
.ArrowDown {
  margin-top: 1.8rem;
  cursor: pointer;
}
.ProfileWrapper {
  display: flex;
  cursor: pointer;
}
.ProfileContainer {
  display: flex;
}
.ProfileName {
  margin: 1rem;
  font-size: var(--font-16);
}
.ProfileErrorIcon {
  margin-left: 1rem;
}
.Hamburger {
  display: none;
  cursor: pointer;
}
.BorderBottom {
  width: 100%;
  height: 1px;
  background-color: #dedfe2;
  border: none;
  outline: none;
}
.HamburgerCategoriesWrapper {
  display: none;
}
.Dropdown {
  position: absolute;
  width: 21.4rem;
  background: var(--pure-white);
  box-shadow: 0px 0px 4px rgba(9, 17, 33, 0.18);
  border-radius: 0.8rem;
  display: grid;
  padding: 1rem;
  z-index: 2;
  top: 6.5rem;
  right: 0;
}
.DropdownItems {
  font-weight: 500;
  font-size: 16px;
  padding: 1rem;
  cursor: pointer;
}
.Line {
  width: 17.4rem;
  margin: 1rem;
  background-color: var(--ter-grey);
  height: 1px;
  border: none;
  outline: none;
}
.Navbar .MenuBtn {
  border: 0px;

  font-size: var(--font-20);
  font-weight: 400;
}

.ActiveMenu {
  color: var(--sec-blue);
  border: 0px;
  font-size: var(--font-20);
  font-weight: 400;
}

@media only screen and (max-width: 950px) {
  .Profile,
  .ProfileName,
  .ArrowDown,
  .BorderBottom,
  .CategoriesWrapper,
  .ProfileErrorIcon,
  .Dropdown {
    display: none;
  }
  .Hamburger {
    display: flex;
  }
  .HamburgerCategoriesWrapper {
    position: absolute;
    z-index: 10;
    background-color: var(--pure-white);
    width: 100%;
    display: flex;
    gap: 3.2rem;
    flex-direction: column;
    padding: 1rem 2rem;
    transition: visibility 0.3s, height 0.15s, opacity 0.15s ease-in-out;
  }
  .CategoryWrapper {
    height: fit-content;
  }
  .Category {
    font-size: var(--font-18);
    font-weight: 500;
  }
  .Logo {
    width: 8rem;
  }
  .CategoryWrapperActive {
    border: none;
  }
  .LowerNavWrapper {
    display: flex;
    flex-direction: column;
    gap: 3.2rem;
    padding-top: 3.2rem;
    border-top: 1px solid var(--sec-grey);
  }
}
